import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { GlobalAudio } from 'app/components/Common/Audio';
import { logger } from 'utils/helpers';
import IconAudioPlay from '../icons/IconAudioPlay';
import IconAudioStop from '../icons/IconAudioStop';
import IconAudioNext from '../icons/IconAudioNext';
import IconAudioHandler from '../icons/IconAudioHandler';

const PlayerBarWrap = styled.div`
  width: 100%;
  z-index: 30;
  max-width: 600px;
`;
const ProgressBarWrap = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  background: #e1e1e1;
`;
const ProgressBar = styled.div<any>`
  width: ${props => `${props.progress}%`};
  height: 100%;
  background: #ff730d;
`;
const AudioHandler = styled.div`
  position: absolute;
  z-index: 1;
  top: -10px;
  left: -13px;
`;
const PlayerBarItemWrap = styled.div`
  width: 100%;
  height: 64px;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  padding: 0 16px;
`;
const AudioInfoWrap = styled.div`
  display: flex;
  align-items: center;
`;
const AudioButtonsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const PlayerBarImg = styled.div<any>`
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  background: ${props => `url('${props.src}') center center no-repeat`};
  background-size: cover;
`;
const PlayerTrackTextWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  b {
    padding-bottom: 1px;
    color: #1a1a1a;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 390px) {
      max-width: 120px;
    }
  }
`;
const AudioButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 30px;
  height: 30px;
`;

function MiniAudioPlayer({
  track,
  isPlaying,
  setIsPlaying,
  audioRef,
  trackList,
  isContextAudioPlaying,
  activeTrack,
}) {
  const audio_tracks = trackList !== null ? trackList : track;
  const [currPlayTime, setCurrPlayTime] = useState(0);
  const [totalPlayTime, setTotalPlayTime] = useState(0);

  const play = async () => {
    logger.log('PLAY REQUESTED', audioRef);
    if (audioRef.current && !isPlaying) {
      await audioRef.current.play();
    }
  };

  const pause = () => {
    _.result(audioRef.current, 'pause');
  };

  const fastForward = sec => {
    if (audioRef.current) {
      audioRef.current.currentTime = currPlayTime + sec;
    }
  };

  useEffect(() => {
    const playTrack = async () => {
      if (!!audio_tracks) {
        try {
          await play();
        } catch (err) {
          logger.log('TRACK PLAY FAILED', err);
        }
      }
    };

    playTrack();

    return () => {
      pause();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio_tracks]);

  useEffect(() => {
    if (!isContextAudioPlaying && trackList !== null && track !== null) {
      pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContextAudioPlaying]);

  const progress = (currPlayTime * 100) / totalPlayTime;

  return (
    <PlayerBarWrap>
      <ProgressBarWrap>
        <ProgressBar progress={progress} />

        <AudioHandler style={{ left: `calc(${progress}% - 13px)` }}>
          <IconAudioHandler />
        </AudioHandler>
      </ProgressBarWrap>

      <PlayerBarItemWrap>
        <AudioInfoWrap>
          <PlayerBarImg src={_.get(activeTrack, 'track_images.0.image')} />

          <PlayerTrackTextWrap>
            {!!_.get(activeTrack, 'name') && (
              <b>{_.get(activeTrack, 'name')}</b>
            )}
          </PlayerTrackTextWrap>
        </AudioInfoWrap>

        <AudioButtonsWrap>
          {isPlaying ? (
            <AudioButton onClick={pause}>
              <IconAudioStop />
            </AudioButton>
          ) : (
            <AudioButton onClick={play}>
              <IconAudioPlay />
            </AudioButton>
          )}
          <AudioButton onClick={() => fastForward(5)}>
            <IconAudioNext />
          </AudioButton>
        </AudioButtonsWrap>
      </PlayerBarItemWrap>

      <GlobalAudio
        audioRef={audioRef}
        track={activeTrack}
        setIsPlaying={setIsPlaying}
        setCurrPlayTime={setCurrPlayTime}
        setTotalPlayTime={setTotalPlayTime}
      />
    </PlayerBarWrap>
  );
}

export default MiniAudioPlayer;

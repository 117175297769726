import { useCallback, useState } from 'react';
import { useFirstMountState } from 'react-use';
import useDeepCompareEffect from 'react-use/lib/useDeepCompareEffect';

export function useUpdateDeepCompareEffect(effect, deps) {
  const isFirstMount = useFirstMountState();

  useDeepCompareEffect(() => {
    if (!isFirstMount) {
      return effect();
    }
  }, deps);
}

export function useDeepCompareCallback(func, dependencies) {
  const [deps, setDeps] = useState(dependencies);
  useUpdateDeepCompareEffect(() => {
    setDeps(dependencies);
  }, dependencies);

  // eslint-disable-next-line
  const cb = useCallback(func, deps);

  return cb;
}

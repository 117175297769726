import { Portal, Zoom } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import { getError } from 'utils/helpers';

const Message = styled.div`
  z-index: 100000;
  padding: 24px;
  border-radius: 16px;
  position: fixed;
  left: 50%;
  bottom: 60px;
  transform: translateX(-50%) !important;
  background-color: rgba(0, 0, 0, 0.4);
  p {
    font-size: 14px;
    color: #fff;
  }
`;

export function ErrorAlert({ errored, msg, onClose = _.noop }) {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    let timeout;
    if (errored) {
      setIsOpen(true);
      timeout = setTimeout(() => {
        setIsOpen(false);
      }, 2000);
    }

    return () => clearTimeout(timeout);
  }, [errored]);

  useUpdateEffect(() => {
    if (!isOpen) {
      onClose();
    }
  }, [isOpen]);

  return (
    <Portal>
      <Zoom in={isOpen}>
        {msg ? (
          <Message>
            <p>{msg}</p>
          </Message>
        ) : (
          <div />
        )}
      </Zoom>
    </Portal>
  );
}

export function ApiErrorAlert({ apiResult, ...rest }) {
  const { errored, errMsg } = getError(apiResult);
  return <ErrorAlert errored={errored} msg={errMsg} {...rest} />;
}

import { lazyLoad } from 'utils/loadable';

export const UserReviewPage = lazyLoad(
  () => import('./index'),
  module => module.UserReviewPage,
);

export const AllPassUnwrittenReviewPage = lazyLoad(
  () => import('./AllPassUnwrittenReviewPage'),
  module => module.AllPassUnwrittenReviewPage,
);

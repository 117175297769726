/**
 * Create the store with dynamic reducers
 */

import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { analyticsReducer } from 'app/pages/App/analyticsSlice';
import {
  authReducer,
  refreshTokenSaga,
  REFRESH_ACCESS_TOKEN,
} from 'app/pages/App/authSlice';
import { userReducer } from 'app/pages/App/userSlice';
import { persistReducer } from 'redux-persist';
import storageLocal from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import createSagaMiddleware from 'redux-saga';
import { takeLeading } from 'redux-saga/effects';

function* rootSaga() {
  yield takeLeading(REFRESH_ACCESS_TOKEN, refreshTokenSaga);
}

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware];

  const reducers = combineReducers({
    auth: persistReducer({ key: 'auth', storage: storageLocal }, authReducer),
    analytics: persistReducer(
      { key: 'analytics', storage: storageSession },
      analyticsReducer,
    ),
    user: userReducer,
  });

  const store = configureStore({
    reducer: reducers,
    middleware: [...getDefaultMiddleware(), ...middlewares],
    devTools: process.env.REACT_APP_ENV !== 'production',
  });

  sagaMiddleware.run(rootSaga);

  return store;
}

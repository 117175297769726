import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { routes } from 'utils/const';
import { useAudioContext } from '../Common/Audio';
import { Link } from '../Common/Link';
import MiniAudioPlayer from '../Common/MiniAudioPlayer';

const Root = styled.footer`
  position: fixed;
  width: 100%;
  max-width: 600px;
  bottom: 0px;
  background: #ffffff;
  z-index: 10;
`;

const FooterWrap = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #c4c4c4;
`;

const Menu = styled(Link)`
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &:nth-child(1) img {
    width: 22px;
  }
  &:nth-child(2) img {
    width: 22px;
  }
  &:nth-child(3) img {
    width: 22px;
  }
`;

const FooterIconWrap = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FooterIcon = styled.img`
  height: auto;
`;

const FooterMenuName = styled.p<any>`
  font-size: 9px;
  color: ${props => (props.isSelected ? '#F19B00' : '#919191')};
`;

export const FooterContext = React.createContext<any>(undefined);

export function FooterPortal({ children }) {
  const { ref } = React.useContext(FooterContext);
  return !!ref ? ReactDOM.createPortal(children, ref as Element) : <></>;
}

interface FooterPropsType {
  audioRef?: any;
  tour?: any;
  allpass?: any;
  showMiniPlayer?: boolean;
  tracks?: any;
  isPlaying?: boolean;
  setIsPlaying?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowMiniPlayer?: any;
  activeTrack?: any;
}

export function Footer({
  audioRef,
  tour = null,
  allpass = null,
  setShowMiniPlayer,
  showMiniPlayer,
  tracks,
  isPlaying,
  setIsPlaying,
  activeTrack,
}: FooterPropsType) {
  const location = useLocation();
  const { track, isPlaying: isContextAudioPlaying } = useAudioContext();
  const { setRef } = useContext(FooterContext);
  const didBuyAllPass = _.get(allpass, 'is_purchased');
  const releasedAt = _.get(tour, 'released_at');
  const didBuyTour = _.get(tour, 'is_purchased');
  const audio_tracks = tracks?.filter(
    track => track.is_sample_file === true,
  )[0];
  useEffect(() => {
    if (track !== null && audio_tracks !== null) {
      setShowMiniPlayer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio_tracks]);

  return (
    <>
      <Root>
        <div ref={ref => setRef(ref)} />
        {showMiniPlayer && (
          <MiniAudioPlayer
            audioRef={audioRef}
            trackList={track}
            track={audio_tracks}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            isContextAudioPlaying={isContextAudioPlaying}
            activeTrack={activeTrack}
          />
        )}

        {!(didBuyTour || didBuyAllPass || !releasedAt) ? null : (
          <FooterWrap>
            {[
              {
                active: require('assets/images/search-yellow.png'),
                inactive: require('assets/images/search.png'),
                text: '검색',
                to: routes.MAIN,
                selected: _.includes(
                  [routes.MAIN, `${routes.MAIN}/`],
                  location.pathname,
                ),
              },
              {
                active: require('assets/images/suitcase-yellow.png'),
                inactive: require('assets/images/suitcase.png'),
                text: '나의 여행',
                to: routes.USER_PURCHASED_TOUR,
                selected: _.includes(
                  [
                    routes.USER_PURCHASED_TOUR,
                    `${routes.USER_PURCHASED_TOUR}/`,
                  ],
                  location.pathname,
                ),
              },
              {
                active: require('assets/images/human-yellow.png'),
                inactive: require('assets/images/human.png'),
                text: '프로필',
                to: routes.MY_PAGE,
                selected: _.includes(
                  [routes.MY_PAGE, `${routes.MY_PAGE}/`],
                  location.pathname,
                ),
              },
            ].map(it => (
              <Menu key={it.to} to={it.to}>
                <FooterIconWrap>
                  <FooterIcon
                    src={it.selected ? it.active : it.inactive}
                    alt={it.text}
                  />
                </FooterIconWrap>
                <FooterMenuName isSelected={it.selected}>
                  {it.text}
                </FooterMenuName>
              </Menu>
            ))}
          </FooterWrap>
        )}
      </Root>
      <div style={{ height: !!track ? 95 : 55 }} />
    </>
  );
}

export default Footer;

/**
 * Asynchronously loads the component for HomePage
 */

import { lazyLoad } from 'utils/loadable';

export const CheckoutPage = lazyLoad(
  () => import('./index'),
  module => module.CheckoutPage,
);

export const PayConfirmationPage = lazyLoad(
  () => import('./PayConfirmationPage'),
  module => module.PayConfirmationPage,
);

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
export const initialUser = {
  id: null,
  is_social_login: false,
  password: '',
  last_login: null,
  is_superuser: false,
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  is_staff: false,
  date_joined: null,
  is_active: true,
  is_identified: false,
  is_email_confirmed: false,
  is_sms_authenticated: false,
  is_marketing_sub: false,
  thumbnail: null,
  image: null,
  alias: null,
  level: 0,
  age: null,
  age_range: 0,
  country_code: null,
  mobile: null,
  gender: null,
  social_ci: null,
  created_at: null,
  updated_at: null,
  deleted_at: null,
  groups: [],
  user_permissions: [],
  coupons: [],
};

const slice = createSlice({
  name: 'user',
  initialState: initialUser,
  reducers: {
    changeUser(state, action: PayloadAction<any>) {
      _.keys(action.payload).forEach(key => {
        _.set(state, key, action.payload[key]);
      });
    },
    initUser(state) {
      _.keys(initialUser).forEach(key => {
        _.set(state, key, initialUser[key]);
      });
    },
  },
});

export const { actions: userActions, reducer: userReducer } = slice;

const selectDomain = state => state.user || initialUser;

export const userSelector = createSelector(
  [selectDomain],
  userState => userState,
);

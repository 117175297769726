import React from 'react';
import type { SVGProps } from 'react';

export default function IconAudioStop(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="7" y="5" width="3" height="20" fill="#1A1A1A" />
      <rect x="20" y="5" width="3" height="20" fill="#1A1A1A" />
    </svg>
  );
}

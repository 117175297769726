import React from 'react';
import type { SVGProps } from 'react';

export default function IconAudioNext(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 23L18 15L6 7L6 23Z"
        fill="#1A1A1A"
        stroke="#1A1A1A"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect x="21" y="5.5" width="2" height="19" fill="#1A1A1A" />
    </svg>
  );
}

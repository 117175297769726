import { selectAccessToken } from 'app/pages/App/authSlice';
import { userActions } from 'app/pages/App/userSlice';
import _ from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import useDeepCompareEffect from 'react-use/lib/useDeepCompareEffect';
import { API_HOST, routes } from 'utils/const';
import { logger } from 'utils/helpers';
import { useAuthAxios } from './useAuthAxios';

export function useUser() {
  const { getUser } = useUserFetch();

  useEffect(() => {
    logger.log('GET USER CHANGED');
    getUser();
  }, [getUser]);
}

export function useUserFetch() {
  const [getUserResult, getUser] = useAuthAxios(
    {
      method: 'get',
      url: `${API_HOST}/v1/users`,
    },
    { manual: true },
  );

  const successStatus = _.get(getUserResult, 'response.status');
  const user = _.get(getUserResult, 'response.data.data');
  const dispatch = useDispatch();
  useDeepCompareEffect(() => {
    if (successStatus === 200) {
      const action = userActions.changeUser(user);
      dispatch(action);
    }
  }, [successStatus, user]);

  return { getUserResult, getUser };
}

export function useUserRouteGuard(shouldComeBack?) {
  const history = useHistory();
  const accessToken = useSelector(selectAccessToken);
  const location = useLocation();

  useEffect(() => {
    if (!accessToken) {
      let url = routes.SELECT_LOGIN_OPTION;
      const redirect = `${location.pathname}${location.search}`;
      let comeback = `/?redirect=${encodeURIComponent(redirect)}`;
      shouldComeBack && (url += comeback);

      logger.log('STATE', (history as any).state);

      history.push({ pathname: url, state: { prevPath: redirect } });
    }
  }, [accessToken, shouldComeBack, history, location]);
}

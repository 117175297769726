import { lazyLoad } from 'utils/loadable';

export const EditReviewPage = lazyLoad(
  () => import('./EditReviewPage'),
  module => module.EditReviewPage,
);

export const NewReviewPage = lazyLoad(
  () => import('./NewReviewPage'),
  module => module.NewReviewPage,
);

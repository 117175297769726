import _ from 'lodash';
import { useLocation } from 'react-router';
import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import { ko } from 'date-fns/locale';
import { ErrorCodeTable } from './const';

(window as any).logLevel = process.env.REACT_APP_LOG_LEVEL || 'log';
const logLevelTable = {
  debug: 0,
  log: 1,
  error: 2,
};
export const logger = (function () {
  const logger: any = {};
  if (process.env.REACT_APP_ENV === 'production') {
    logger.log = () => undefined;
    logger.debug = () => undefined;
    logger.error = () => undefined;
  } else {
    logger.debug = (...args) => {
      if (logLevelTable[(window as any).logLevel] <= logLevelTable['debug']) {
        console.info(...args);
      }
    };
    logger.log = (...args) => {
      if (logLevelTable[(window as any).logLevel] <= logLevelTable['log']) {
        console.log(...args);
      }
    };
    logger.error = (...args) => {
      if (logLevelTable[(window as any).logLevel] <= logLevelTable['error']) {
        console.error(...args);
      }
    };
  }

  return logger;
})();

export const comma = number => {
  return (number || 0).toLocaleString('ko-KR');
};

export function getMobileOperatingSystem() {
  var userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}

export function timeStrToSemanticStr(str) {
  return _.split(str, ':')
    .map((it, index) => {
      const time = +it;
      if (!time) return null;
      else if (index === 0) return `${time}시간`;
      else if (index === 1) return `${time}분`;

      return null;
    })
    .filter(_.identity)
    .join(' ');
}

export function useQuery(): any {
  return new URLSearchParams(useLocation().search);
}

export function formatDate(date, form = 'yyyy.MM.dd') {
  let result;
  try {
    if (!!date) {
      result = format(new Date(date), form);
    } else {
      result = '';
    }
  } catch (err) {
    console.error(err);
  }

  return result;
}

export function fromNowDate(date) {
  return formatDistance(new Date(date), new Date(), {
    addSuffix: true,
    locale: ko,
  });
}

export function getError(apiResult) {
  const errorCode = _.get(apiResult, 'error.response.data.error_code');
  const loading = _.get(apiResult, 'loading');
  const errored = _.get(apiResult, 'error.response.status') >= 400 && !loading;
  const msg = errored
    ? ErrorCodeTable[errorCode] || '기타 오류가 발생했습니다'
    : '';
  return { errored, errMsg: msg, errorCode };
}

export function isInViewport(el) {
  const rect = _.result(el, 'getBoundingClientRect');
  return (
    _.get(rect, 'top') >= 0 &&
    _.get(rect, 'left') >= 0 &&
    _.get(rect, 'bottom') <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    _.get(rect, 'right') <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
}

export const formatNumber = (num = 0): string => {
  if (num >= 10000) {
    return numberWithComma((num / 10000).toFixed(1)) + '만';
  }
  return numberWithComma(num.toFixed(0));
};

export const numberWithComma = (num: string | number = 0): string => {
  return Number(num).toLocaleString();
};

export const convertTimeStringToKoreanFormat = (
  timeString: string = '',
): string => {
  const [hours, minutes] = timeString.split(':').map(Number);
  const hourString = hours > 0 ? `${hours}시간` : '';
  const minuteString = minutes > 0 ? `${minutes}분` : '';
  return `${hourString} ${minuteString}`;
};

export const parseLineHeight = (lineHeight, fontSize) => {
  if (lineHeight === 'normal') {
    return fontSize * 1.2; // 일반적인 default 값 추정
  } else if (lineHeight.endsWith('em')) {
    return fontSize * parseFloat(lineHeight); // em 단위 처리
  } else if (lineHeight.endsWith('%')) {
    return (fontSize * parseFloat(lineHeight)) / 100; // % 단위 처리
  } else if (lineHeight.endsWith('rem')) {
    const rootFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize,
    ); // rem 기준은 루트 폰트 크기
    return rootFontSize * parseFloat(lineHeight); // rem 단위 처리
  } else {
    return parseFloat(lineHeight) || fontSize * 1.2; // 숫자 변환 실패 시 폴백
  }
};

import axios, { AxiosResponse } from 'axios';
import produce from 'immer';
import _ from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDeepCompareEffect from 'react-use/lib/useDeepCompareEffect';
import { logger } from 'utils/helpers';
import {
  REFRESH_ACCESS_TOKEN,
  selectAccessToken,
  selectRefreshToken,
} from '../../app/pages/App/authSlice';
import { useDeepCompareCallback } from './useDeepCompare';

export function useAuthAxios(axiosOps, hookOps?): any {
  const accessToken = useSelector(selectAccessToken);
  const refreshToken = useSelector(selectRefreshToken);

  const hookOptions: {
    manual: boolean;
  } = produce(hookOps, draft => {
    return _.defaults(draft, { manual: false });
  }) as any;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosResponse | undefined>();
  const [response, setResponse] = useState<AxiosResponse | undefined>();

  const dispatch = useDispatch();

  const execute = useDeepCompareCallback(
    async function (op = {}) {
      setLoading(true);
      let res;
      try {
        const defaultOp: any = produce(axiosOps, draft => {
          return getDefaultOp(draft, accessToken);
        });

        const isEvent = op instanceof Event;
        const safeOp = isEvent ? {} : op;
        const axiosOp: any = produce(safeOp, draft => {
          return _.defaultsDeep(draft, defaultOp);
        });

        res = await axios(axiosOp)
          .then(res => res)
          .catch(err => err);
        logger.log('AXIOS RES', axiosOp, accessToken, res);
        const responseStatus = _.get(res, 'response.status');
        console.log('responseStatus: ' + responseStatus);
        console.log('res.status: ' + res.status);
        if (res.status >= 200 && res.status < 300) {
          setResponse(res);
          setError(undefined);
        } else if (_.get(res, 'response.status') === 401 && !!refreshToken) {
          logger.log('AUTH DISPATCHING');
          dispatch({ type: REFRESH_ACCESS_TOKEN });
          setError(res);
          setResponse(undefined);
        } else {
          logger.log('AXIOS ERR RES', res);
          setError(res);
          setResponse(undefined);
        }
      } catch (err) {
        logger.error('AXIOS ERR', err);
      } finally {
        setLoading(false);
      }
    },
    [axiosOps, accessToken, refreshToken, dispatch],
  );

  const isManual = hookOptions.manual;
  useDeepCompareEffect(() => {
    logger.log('EXECUTE CHANGED');
    if (!isManual) {
      execute();
    }
  }, [isManual, execute]);

  function init() {
    setResponse(undefined);
    setError(undefined);
    setLoading(false);
  }

  return [
    { loading, error, response, data: _.get(response, 'data') },
    execute,
    init,
  ];
}

function getDefaultOp(userOption, accessToken) {
  return _.defaultsDeep(
    userOption,
    !!accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : null,
  );
}

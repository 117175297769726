import { call, put, select } from '@redux-saga/core/effects';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from 'lodash';
import { API_HOST, USER_AUTH } from 'utils/const';
import { logger } from 'utils/helpers';

export const initialState = {
  accessToken: '',
  refreshToken: '',
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeTokens(
      state,
      action: PayloadAction<{
        accessToken: string;
        refreshToken: string;
      }>,
    ) {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    loadTokens() {},
  },
});

export const { actions: authActions, reducer: authReducer } = slice;

const selectDomain = state => state.auth || initialState;

export const selectAccessToken = createSelector(
  [selectDomain],
  authState => authState.accessToken,
);

export const selectRefreshToken = createSelector(
  [selectDomain],
  authState => authState.refreshToken,
);

export const REFRESH_ACCESS_TOKEN = 'RERESH_ACCESS_TOKEN';

function getPostToken(payload) {
  return axios({
    method: 'POST',
    url: `${API_HOST}/v1/auth/token`,
    data: payload,
    auth: USER_AUTH,
  });
}
export function* refreshTokenSaga() {
  try {
    const refreshToken = yield select(selectRefreshToken);
    if (!refreshToken) {
      logger.log('NO TOKEN');
      return;
    }
    const payload = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    };
    logger.log('TOKEN REFRESHING', payload);

    const res = yield call(getPostToken, payload);
    const action = authActions.changeTokens({
      accessToken: _.get(res, 'data.data.access_token'),
      refreshToken: _.get(res, 'data.data.refresh_token'),
    });
    logger.log('REFRESH RESULT', res, action);
    yield put(action);
  } catch (err) {
    const action = authActions.changeTokens({
      accessToken: '',
      refreshToken: '',
    });
    logger.log('REFRESH INIT', err, action);
    yield put(action);
  }
}

import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Header = styled.header`
  width: 100%;
  position: relative;
  z-index: 10;
`;

const HeaderWrap = styled.article`
  width: 100%;
  height: 98px;
  display: flex;
  padding: 0 20px;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
`;

const IconHistoryBack = styled.img`
  width: 16px;
  left: 20px;
  cursor: pointer;
`;

const SubjectHistoryBack = styled.h1`
  font-size: 29px;
  width: 100%;
  text-align: left;
  color: #222222;
  font-weight: 600;
  margin-top: 25px;
`;

const HeaderButton = styled.button`
  padding: 7px 10px;
  color: #fff;
  background: ${({ theme }) => theme.colors.colorPoint};
  font-size: 12px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

function HeaderHistoryBack({
  subject = '',
  showBackIcon = false,
  button,
  onBtnClick,
  className = '',
}: any) {
  const history = useHistory();

  return (
    <Header className={className}>
      <HeaderWrap>
        {showBackIcon && (
          <IconHistoryBack
            src={require('assets/images/left.png')}
            onClick={history.goBack}
          />
        )}

        <SubjectHistoryBack>{subject}</SubjectHistoryBack>
        {button && <HeaderButton onClick={onBtnClick}>{button}</HeaderButton>}
      </HeaderWrap>
    </Header>
  );
}

export default HeaderHistoryBack;

import { userSelector } from 'app/pages/App/userSlice';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { routes } from 'utils/const';
import { Anchor, Link } from '../Common/Link';
import AppVersion from '../style/common/AppVersion';
import { KakaoLoginButton } from './NoneLogin';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { authActions } from 'app/pages/App/authSlice';
import { userActions } from 'app/pages/App/userSlice';

const MyPageWrap = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MyPageLogInWrap = styled.div`
  width: 100%;
  padding: 40px 0 50px;
  margin-bottom: 15px;
  border-bottom: 1px solid #dddddd;
  background: #ffffff;
  text-align: center;

  button {
    width: 260px;
    height: 48px;
    background: #3e3a39;
    border-radius: 4px;
    margin: 0 auto;
    text-align: center;
    display: block;
    font-weight: 900;
    font-size: 14px;
    color: #ffffff;

    img {
      width: 17px;
      margin-right: 10px;
    }
  }

  p {
    text-align: center;
    margin-top: 23px;
  }

  a {
    text-decoration: underline;
    color: #3e3a39;
    line-height: 19.5px;
    font-size: 13px;
  }
`;

const MyPageSubContentWrap = styled.div<{ top?: boolean }>`
  width: 100%;
  padding: 0 20px 10px 20px;
  background-color: #ffffff;
  &:last-of-type {
    margin-bottom: 0;
  }
  ul {
    li {
      a {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f6f4f2;
        background: #ffffff;
        text-decoration: none;
        h2 {
          font-weight: 500;
          font-size: 13px;
          color: #222222;
          margin-right: 10px;
        }
        span {
          font-weight: 500;
          font-size: 13px;
          color: #f19b00;
        }
        img {
          width: 8px;
          margin-left: auto;
        }
      }
    }
  }
`;

const MyPageProfileWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 0;
  background: #fff;
`;

const ProfileWrap = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 64px;
    height: 64px;
    border-radius: 50px;
    margin-right: 11px;
  }
`;

const ProfileTitle = styled.h3`
  padding: 0px 20px;
  color: #222222;
  font-weight: 600;
  font-size: 17px;
  margin-top: 20px;
  margin-bottom: 11px;
`;

const ProfileTitleLast = styled.h2`
  padding: 0px 20px;
  margin-top: 20px;
  text-decoration: underline;
  margin-bottom: 40px;
  cursor: pointer;
  font-size: 13px;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 5px;
    font-weight: 600;
    color: #000000;
  }

  span {
    font-weight: normal;
    font-size: 12px;
    color: #919191;
    font-weight: 400;
    display: block;
  }
`;

const ProfileEditButton = styled.button`
  padding: 8px 14px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  background: #222222;
  border-radius: 12px;
  color: #fff;
`;

const KakaoBtn = styled(KakaoLoginButton)`
  max-width: 260px;
  margin: 0 auto;
`;

// const AppleBtn = styled(AppleButton)`
//   max-width: 260px;
//   margin: 0 auto;
// `;

const MyPageLogIn = () => {
  const user = useSelector(userSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  function logout() {
    const authAction = authActions.changeTokens({
      accessToken: '',
      refreshToken: '',
    });
    dispatch(authAction);

    const userAction = userActions.initUser();
    dispatch(userAction);

    history.push(routes.MY_PAGE);
  }

  return (
    <MyPageWrap>
      <div>
        {!!user.id ? (
          <MyPageSubContentWrap top={true}>
            <MyPageProfileWrap>
              <ProfileWrap>
                <img
                  src={
                    user.thumbnail || require('assets/images/ico_profile.png')
                  }
                  alt="profile-icon"
                />
                <ProfileInfo>
                  {!!user.fullname && <h2>{user.fullname}</h2>}
                  <span>{user.email || ''}</span>
                </ProfileInfo>
              </ProfileWrap>
              <Link to={routes.USER_PROFILE} style={{ marginLeft: 'auto' }}>
                <ProfileEditButton>계정 관리</ProfileEditButton>
              </Link>
            </MyPageProfileWrap>
          </MyPageSubContentWrap>
        ) : (
          <MyPageLogInWrap>
            <KakaoBtn style={{ maxWidth: 260 }} />
            {/* <AppleBtn style={{ maxWidth: 260 }} /> */}
            <p>
              <Link to={routes.USER_LOGIN}>이메일로 로그인하기</Link>
            </p>
          </MyPageLogInWrap>
        )}
        {user.id && (
          <MyPageSubContentWrap>
            <ul>
              {[
                {
                  to: routes.USER_REVIEW_LIST,
                  title: '나의 후기',
                  extraText: '리뷰 작성시 500포인트!',
                },
                {
                  to: routes.POINTS,
                  title: '포인트',
                },
                {
                  to: routes.USER_COUPONS,
                  title: '쿠폰함',
                  extraText:
                    user.available_coupons > 0
                      ? `보유쿠폰 ${user.available_coupons}개`
                      : ``,
                },
                { to: routes.USER_PURCHASES, title: '결제내역' },
              ].map(it => (
                <li key={it.title}>
                  <Link to={it.to}>
                    <h2>{it.title}</h2>
                    {it.extraText && <span>{it.extraText}</span>}
                    <img
                      src={require('assets/images/right.png')}
                      alt={it.title}
                    />
                  </Link>
                </li>
              ))}
            </ul>
          </MyPageSubContentWrap>
        )}

        <ProfileTitle>크리에이터</ProfileTitle>
        <MyPageSubContentWrap>
          <ul>
            {[
              {
                to: 'https://creatortourlive.oopy.io/',
                title: '크리에이터 페이지',
                extraText: '콘텐츠 제작하고 평생 연금 받으세요',
              },
            ].map(({ title, to, extraText }) => (
              <li key={title}>
                <Anchor href={to} target="_blank">
                  <h2>{title}</h2>
                  <span>{extraText}</span>
                  <img src={require('assets/images/right.png')} alt={title} />
                </Anchor>
              </li>
            ))}
          </ul>
        </MyPageSubContentWrap>

        <ProfileTitle>고객센터</ProfileTitle>
        <MyPageSubContentWrap>
          <ul>
            {[
              {
                to:
                  'https://tourliverocket.notion.site/5ba8c0660cd442b9aad834087518a165',
                title: '공지사항',
              },
              {
                to:
                  'https://tourliverocket.notion.site/faq-300b5b83f42c4e21b4e20c23ac5001fa',
                title: '자주 묻는 질문 (FAQ)',
              },
              {
                to: 'http://pf.kakao.com/_xjxkxbLC',
                title: '카카오톡 고객센터',
              },
            ].map(({ title, to }) => (
              <li key={title}>
                <Anchor href={to} target="_blank">
                  <h2>{title}</h2>
                  <img src={require('assets/images/right.png')} alt={title} />
                </Anchor>
              </li>
            ))}
          </ul>
        </MyPageSubContentWrap>

        {user.id && (
          <span onClick={logout}>
            <ProfileTitleLast>로그아웃</ProfileTitleLast>
          </span>
        )}

        <Link to={`${routes.TERMS_OF_SERVICE}/?title=이용약관`}>
          <ProfileTitleLast>이용약관</ProfileTitleLast>
        </Link>
      </div>
      <AppVersion />
    </MyPageWrap>
  );
};

export default MyPageLogIn;

import React from 'react';
import type { SVGProps } from 'react';

export default function IconAudioHandler(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      {...props}
    >
      <g filter="url(#filter0_d_77_3915)">
        <circle cx="12.5" cy="11.5" r="8.5" fill="#FF730D" />
        <circle cx="12.5" cy="11.5" r="3.5" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_d_77_3915"
          x="-4"
          y="-2"
          width="33"
          height="33"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_77_3915"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_77_3915"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

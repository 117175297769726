import { selectAccessToken } from 'app/pages/App/authSlice';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RrdLink, matchPath, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { authRoutes, routes } from 'utils/const';

export const LinkComp = styled(RrdLink)`
  text-decoration: none;
  outline: 0;

  &:active {
    outline: 0 !important;
  }

  &:focus:not(:focus-visible) {
    outline: 0 !important;
  }
`;

export function Link(props) {
  const history = useHistory();
  const accessToken = useSelector(selectAccessToken);
  function onClick(e) {
    _.result(props, 'onClick');
    e.preventDefault();
    const isAuthPath = _.values(authRoutes).reduce((acc: any, curr) => {
      return matchPath(props.to, curr) || acc;
    }, false);

    if (isAuthPath && !accessToken) {
      let url = routes.SELECT_LOGIN_OPTION;
      let comeback = `/?redirect=${encodeURIComponent(props.to)}`;
      url += comeback;

      return history.push(url);
    }

    history.push(props.to);

    if (typeof props.scrollTo === 'number') {
      window.scrollTo(props.scrollTo, 0);
    }
  }

  return <LinkComp onClick={onClick} {...props} />;
}

export const LinkUnderlined = styled(Link)`
  text-decoration: underline;
`;

export const Anchor = styled.a`
  outline: 0;
  text-decoration: none;

  &:active {
    outline: 0 !important;
  }

  &:focus:not(:focus-visible) {
    outline: 0 !important;
  }
`;

const colors = {
  colorMain: '#3E3A39',
  colorSub: '#808080',
  colorSub2: '#DDDDDD',
  colorWhite: '#FFFFFF',
  colorPoint: '#FF5A5F',
  colorYellow: '#F19B00',
};

const contentPadding = {
  padding: '0 20px 40px',
};

const theme = {
  colors,
  contentPadding,
};

export default theme;

export const authRoutes = {
  CHECKOUT: '/checkout',
  CHECKOUT_CONFIMATION: '/checkout/:checkoutId/confirm',
  CHECKOUT_SUCCESS: '/checkout/:checkoutId/success',
  CHANGE_PASSWORD: '/password/form',
  USER_REVIEW_LIST: '/user/reviews',
  USER_ALL_PASS_REVIEWS: '/user/reviews/all-pass/:packageId',
  USER_REVIEW_FORM_NEW: '/user/tour/:tourId/review/form',
  USER_REVIEW_FORM_EDIT: '/user/tour/:tourId/review/:reviewId/form',
  USER_REVIEW_DETAIL: '/user/tour/:tourId/review/:reviewId',
  USER_PURCHASES: '/user/purchases',
  POINTS: '/user/points',
  USER_PURCHASE_DETAIL: '/user/purchase/:checkoutId',
  USER_CARD: '/user/cards',
  USER_PROFILE: '/user/profile',
};

export const routes = {
  MAIN: '/',
  TOUR: '/tour',
  EMAIL_SIGN_UP: '/signup/email',
  EMAIL_SIGN_UP_SUCCESS: '/signup/email/sucess',
  USER_LOGIN: '/login',
  SELECT_LOGIN_OPTION: '/login/options',
  TOUR_DETAIL_WITH_SLUG: '/tour/:tourId/:nameSlug',
  TOUR_DETAIL: '/tour/:tourId',
  SEARCH: '/tours',
  TOUR_LIST_CITY: '/tours/city/:cityId',
  CITY_CHANGE: '/tours/city/change',
  MY_PAGE: '/my-page',
  USER_PURCHASED_TOUR: '/user/tours',
  USER_COUPONS: '/user/coupons',
  FIND_PASSWORD: '/password',
  DESTINATIONS: '/destinations',
  TERMS_OF_SERVICE: '/terms',
  SITE_MAP: '/sitemap.xml',
  APP_DOWNLOAD: '/app-download',
  ...authRoutes,
};

export const PRIMARY_COLOR = '#ff5a5f';
export const SECONDARY_COLOR = '#61dafb';

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID as string;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET as string;

export const USER_AUTH = {
  username: CLIENT_ID,
  password: CLIENT_SECRET,
};

export const REACT_ENV = process.env.REACT_APP_ENV;
export const KAKAO_JS_KEY = process.env.REACT_APP_KAKAO_JS_KEY;
export const API_HOST = process.env.REACT_APP_API_HOST || '';
export const HASHED_SECRET_KEY = process.env
  .REACT_APP_HASHED_SECRET_KEY as string;

export const ANDROID_PLAY_STORE =
  'https://play.google.com/store/apps/details?id=com.tourlive';
export const IOS_APP_STORE = 'https://apps.apple.com/KR/app/id1473242927?mt=8';

export const UNIVERSAL_STORE_LINK = 'https://tourlive.onelink.me/S6Bk/v7k9exky';

export const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID as string;
export const APPLE_REDIRECT_URL = process.env
  .REACT_APP_APPLE_REDIRECT_URL as string;
export const APPLE_SCOPE = process.env.REACT_APP_APPLE_SCOPE as string;

export enum PayMethod {
  SIMPLE_CARD = 'SIMPLE_CARD',
  COMPLEX_CARD = 'COMPLEX_CARD',
  NAVER_PAY = 'NAVER_PAY',
  KAKAO_PAY = 'KAKAO_PAY',
  TOSS_PAY = 'TOSS_PAY',
  FREE_COUPON = 'FREE_COUPON',
  FREE_TOUR = 'FREE_TOUR',
}

export const PayMethodLabelTable = {
  [PayMethod.SIMPLE_CARD]: '간편하게 카드결제',
  [PayMethod.COMPLEX_CARD]: '복잡하게 카드 결제',
  [PayMethod.NAVER_PAY]: '네이버 페이 결제',
  [PayMethod.KAKAO_PAY]: '카카오 페이 결제',
  [PayMethod.TOSS_PAY]: '토스 결제',
  [PayMethod.FREE_COUPON]: '무료 이용권',
  [PayMethod.FREE_TOUR]: '무료 결제하기',
};

export const PayCodeLabelTable = {
  '0011': '간편하게 카드결제',
  '001': '복잡하게 카드결제',
  '003': '네이버 페이 결제',
  '004': '카카오 페이 결제',
  '0021': '토스 결제',
  '002': '토스 결제',
  '010': '무료 이용권',
  '030': '무료 투어',
};

export const ErrorCodeTable = {
  '99999': '기타 오류가 발생했습니다',
  '8': '잘못된 비밀번호 입니다',
  '9': '잘못된 요청입니다',
  '10': '이메일 인증에 실패하였습니다',
  '11': '임시 토큰 발급에 실패하였습니다',
  '12': '계정 생성에 실패하였습니다',
  '13': '이미 가입한 계정입니다',
  '14': '잘못된 이메일 주소 입니다',
  '100': '잘못된 결제 요청입니다',
  '101': '잘못된 결제 요청입니다',
  '117': '초대코드를 확인해주세요',
  '118': '이미 초대코드를 등록하였습니다',
  '120': '나의초대코드는 등록 할 수 없습니다',
  '200': '결제요청에 문제가 있습니다',
  '201': '결제에 실패하였습니다',
  '1000': '결제에 실패하였습니다',
  '1001': '결제에 실패하였습니다',
  '1002': '결제에 실패하였습니다',
  '1003': '잘못된 세부정보.',
  '1004': '쿠폰번호를 맞게 입력했는지 확인해주세요.',
  '1005': '주문에 실패하였습니다',
  '1006': '결제에 실패하였습니다',
  '1007': '결제에 실패하였습니다',
  '1008': '다운로드에 실패하였습니다',
  '1009': '다운로드에 실패하였습니다',
  '1010': '다운로드에 실패하였습니다',
  '1011': '잘못된 결제 요청입니다',
  '1012': '잘못된 결제 요청입니다',
  '1013': '잘못된 결제 요청입니다',
  '1014': '환불에 실패하였습니다',
  '1015': '가이드 정보가 존재하지 않습니다',
  '1016': '데이터 요청에 실패하였습니다',
  '1017': '잘못된 쿠폰입니다',
  '1018': '주문에 실패하였습니다',
  '1019': '잘못된 요청입니다',
  '1021': '주문에 실패하였습니다',
  '1022': '주문에 실패하였습니다',
  '1023': '쿠폰이 이미 사용되었어요. 고객센터에 문의해주세요.',
  '1024': '잘못된 쿠폰입니다',
  '1025': '이미 구매한 투어입니다',
  '1026': '재생에 실패하였습니다',
  '1027': '잘못된 요청입니다',
  '1028': '아이디 / 비밀번호가 올바르지 않습니다',
  '1029': '허용된 다운로드 횟수를 초과하였습니다',
  '1030': '해당 쿠폰은 로그인 후 등록하실 수 있습니다',
  '1031': '다운로드 가능 기간 만료',
  '1035': '쿠폰기한이 만료되었습니다.',
  '1036': '삭제된 쿠폰코드입니다.',
  '1037':
    '쿠폰을 이미 사용하셨어요. 쿠폰함에서 사용 또는 만료 탭을 확인해주세요.',
  '1038': '쿠폰번호를 맞게 입력했는지 확인해주세요.',
  '1039': '카드를 먼저 등록해주세요.',
  '1040': '포인트/쿠폰 사용으로 환불이 불가합니다.',
  '1041': '잘못된 카드 정보.',
  '1042': '투어가 이미 구매되었습니다.',
  '2001': '결제에 실패하였습니다',
  '2002': '환불에 실패하였습니다. 고객센터로 문의주세요',
  '2003': '환불에 실패하였습니다. 고객센터로 문의주세요',
  '2004': '환불에 실패하였습니다. 고객센터로 문의주세요',
  '2006': '카카오페이 결제에 실패했습니다',
  '2008': '카카오페이 잔액이 부족합니다',
  '2009': '이미 결제가 완료되었습니다',
  '2016': '100원 미만의 금액 결제는 불가합니다',
  '10032': '카드번호가 잘못 입력 되었습니다',
  '10033': '유효기간이 잘못 입력 되었습니다',
  '10034': '비밀번호가 잘못 입력 되었습니다',
  '10035':
    '결제승인이 실패했습니다. 계속 실패되는 경우 카드사에 문의해주시기 바랍니다',
  '10036': '생년월일 또는 사업자번호가 잘못 입력 되었습니다',
  '10037': '카드 등록이 일시적으로 중단되었습니다.',
  '10039': '카드 잔액이 부족하거나 한도가 초과되었습니다',
};

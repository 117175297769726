import CryptoJS from 'crypto-js';
import { HASHED_SECRET_KEY } from './const';

export function encrypt(msgString, key = CryptoJS.SHA256(HASHED_SECRET_KEY)) {
  const iv = CryptoJS.lib.WordArray.random(16);
  var encrypted = CryptoJS.AES.encrypt(msgString, key, {
    iv: iv,
  });
  return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
}

export function decrypt(
  ciphertextStr,
  key = CryptoJS.SHA256(HASHED_SECRET_KEY),
) {
  var ciphertext = CryptoJS.enc.Base64.parse(ciphertextStr);

  // split IV and ciphertext
  var iv = ciphertext.clone();
  iv.sigBytes = 16;
  iv.clamp();
  ciphertext.words.splice(0, 4); // delete 4 words = 16 bytes
  ciphertext.sigBytes -= 16;

  // decryption
  var decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, {
    iv: iv,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

import MyPageLogIn from 'app/components/AccountPages/MyPageLogIn';
import Footer from 'app/components/Main/Footer';
import HeaderHistoryBack from 'app/components/style/common/HeaderHistoryBack';
import * as React from 'react';

export function MyPage() {
  return (
    <>
      <div className="wrapper">
        <div className="container top-header">
          <section>
            <HeaderHistoryBack subject="프로필" />
            <MyPageLogIn />
          </section>
          <Footer />
        </div>
      </div>
    </>
  );
}

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

export const initialState = {};

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    changeAcquisitionParams(state, action: PayloadAction<any>) {
      _.keys(action.payload).forEach(key => {
        _.set(state, key, action.payload[key]);
      });
    },
  },
});

export const { actions: analyticsActions, reducer: analyticsReducer } = slice;

const selectDomain = state => state.analytics || initialState;

export const selectAnalytics = createSelector(
  [selectDomain],
  analyticsState => analyticsState,
);

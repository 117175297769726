import { CircularProgress, Modal as MTModal } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const Modal = styled(MTModal)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: none;
  max-width: 600px;
  min-width: 320px;
  margin: 0 auto;
  left: calc(30vw) !important;
  background: #fff;
  > div {
    background-color: transparent !important;
  }
  @media (max-width: 1024px) {
    left: calc(0vw) !important;
  }
`;

export function Spinner({ loading, modalProps = {} }) {
  return (
    <Modal open={loading} {...modalProps}>
      <CircularProgress />
    </Modal>
  );
}

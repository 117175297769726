import React from 'react';
import styled from 'styled-components';

const AppVersionWrap = styled.div`
  max-width: 600px;
  width: 100%;
  text-align: center;
  padding: 20px 0 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  p {
    margin-top: 35px;
    font-weight: 500;
    font-size: 12px;
    color: #000;
  }
`;

const AppVersionIconWrap = styled.div`
  display: flex;
  justify-content: center;
  a {
    width: 30px;
    height: 30px;
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 18px;
    &:last-of-type {
      margin-right: 0;
    }

    img {
      width: 20px;
    }
  }
`;

const AppVersion = () => {
  return (
    <AppVersionWrap>
      <AppVersionIconWrap>
        {[
          {
            href: 'https://www.instagram.com/tourlive.insta/',
            alt: '인스타그램',
            image: require('assets/images/instagram.png'),
          },
          {
            href:
              'https://www.youtube.com/c/%ED%88%AC%EC%96%B4%EB%9D%BC%EC%9D%B4%EB%B8%8C/',
            alt: '유튜브',
            image: require('assets/images/youtube.png'),
          },
          {
            href: 'https://blog.naver.com/hellotourlive',
            alt: '네이버 블로그',
            image: require('assets/images/naver.png'),
          },
        ].map(it => (
          <a target="_blank" rel="noreferrer" href={it.href} key={it.href}>
            <img src={it.image} alt={it.alt} />
          </a>
        ))}
      </AppVersionIconWrap>
      {/* 웹에서는 특별히 버전 관리를 하지 않아 고정값 반영 */}
      <p>버전 v2.0</p>
    </AppVersionWrap>
  );
};

export default AppVersion;

import {
  analyticsActions,
  selectAnalytics,
} from 'app/pages/App/analyticsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { useQuery } from 'utils/helpers';

export default function useAcquisition() {
  const query = useQuery();
  const utmSource = query.get('utm_source');
  const city = query.get('c');

  const dispatch = useDispatch();
  const analytics = useSelector(selectAnalytics);
  useEffectOnce(() => {
    if (!analytics.utmSource && !!utmSource) {
      const action = analyticsActions.changeAcquisitionParams({
        utmSource,
      });
      dispatch(action);
    }

    if (!analytics.city && !!city) {
      const action = analyticsActions.changeAcquisitionParams({
        city,
      });
      dispatch(action);
    }
  });
}

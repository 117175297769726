import React, { useContext, useState } from 'react';
import _ from 'lodash';
import { logger } from 'utils/helpers';
import useDeepCompareEffect from 'react-use/lib/useDeepCompareEffect';
import CryptoJS from 'crypto-js';
import { decrypt } from 'utils/crypto';
import { HASHED_SECRET_KEY } from 'utils/const';

export function GlobalAudio({
  audioRef,
  track,
  setIsPlaying,
  setCurrPlayTime,
  setTotalPlayTime,
}) {
  const src =
    track &&
    decrypt(_.get(track, 'track_file'), CryptoJS?.SHA256(HASHED_SECRET_KEY));
  return (
    <>
      <audio
        controls
        src={src}
        autoPlay={true}
        ref={audioRef}
        onTimeUpdate={() => {
          const curr = _.result(audioRef, 'current.currentTime');
          logger.log('CURR TIME', curr);
          setCurrPlayTime(curr);
        }}
        onLoadedData={() => {
          const total = _.result(audioRef, 'current.duration');
          logger.log('TOTAL TIME', total);
          setTotalPlayTime(total);
        }}
        onPause={() => setIsPlaying(false)}
        onPlay={() => setIsPlaying(true)}
        style={{ display: 'none' }}
      ></audio>
    </>
  );
}

export const AudioContext = React.createContext({} as any);

export function useAudioControl(audioRef) {
  const [track, setTrack] = useState(null);
  const [currPlayTime, setCurrPlayTime] = useState(0);
  const [totalPlayTime, setTotalPlayTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(1);
  const play = async () => {
    logger.log('PLAY REQUESTED', audioRef);
    !!audioRef && (await audioRef.current.play());
  };
  const pause = () => {
    _.result(audioRef, 'current.pause');
  };
  const fastForward = sec => {
    audioRef.current.currentTime = currPlayTime + sec;
  };
  const rewind = sec => {
    audioRef.current.currentTime = currPlayTime - sec;
  };

  const changeSpeed = speed => {
    audioRef.current.playbackRate = speed;
    setSpeed(speed);
  };

  useDeepCompareEffect(() => {
    playTrack();
    async function playTrack() {
      if (!!track) {
        try {
          await play();
        } catch (err) {
          logger.log('TRACK PLAY FAILED', err);
          setTrack(null);
        }
        setSpeed(audioRef.current.playbackRate);
      }
    }
  }, [track]);

  return {
    play,
    pause,
    fastForward,
    rewind,
    track,
    setTrack,
    currPlayTime,
    setCurrPlayTime,
    totalPlayTime,
    setTotalPlayTime,
    isPlaying,
    setIsPlaying,
    changeSpeed,
    speed,
  };
}

export function useAudioContext() {
  const audio = useContext(AudioContext);

  return audio;
}

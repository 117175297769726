import React from 'react';
import styled from 'styled-components';

const ResultNoneWrap = styled.div`
  width: 100%;
  text-align: center;
  padding: 70px 0 40px;
  align-items: center;
`;

const ResultNoneIcon = styled.img`
  width: 66px;
  height: 66px;
`;

const ResultNoneMessage = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.colorSub};
  margin-top: 25px;
  line-height: 140%;
`;

export const ResetPasswordIcon = () => {
  return (
    <ResultNoneWrap>
      <ResultNoneIcon src={require('assets/images/자물쇠잠금.png')} />
      <ResultNoneMessage>비밀번호가 재설정이 완료되었습니다.</ResultNoneMessage>
    </ResultNoneWrap>
  );
};

export const PaymentNone = () => {
  return (
    <ResultNoneWrap>
      <img
        alt="paymentIcon"
        style={{ width: 66 }}
        src={require('assets/images/영수증.png')}
      />
      <ResultNoneMessage>결제내역이 없습니다.</ResultNoneMessage>
    </ResultNoneWrap>
  );
};

export const MyTourNone = () => {
  return (
    <ResultNoneWrap>
      <img
        alt="paymentIcon"
        style={{ width: 90 }}
        src={require('assets/images/우는스마일.png')}
      />
      <ResultNoneMessage>구매목록이 없어요.</ResultNoneMessage>
    </ResultNoneWrap>
  );
};

export const LoginNone = () => {
  return (
    <ResultNoneWrap>
      <img
        alt="paymentIcon"
        style={{ width: 100 }}
        src={require('assets/images/자물쇠열림.png')}
      />
      <ResultNoneMessage>로그인 후 이용해주세요</ResultNoneMessage>
    </ResultNoneWrap>
  );
};

export const CouponNone = ({ coupon }: any) => {
  return (
    <ResultNoneWrap>
      <img
        alt="paymentIcon"
        style={{ width: 104 }}
        src={require('assets/images/쿠폰.png')}
      />
      <ResultNoneMessage>
        {coupon ? '사용가능한 쿠폰이 없습니다.' : '사용한 쿠폰이 없습니다.'}
      </ResultNoneMessage>
    </ResultNoneWrap>
  );
};

export const ReviewToWriteNone = () => {
  return (
    <ResultNoneWrap>
      <img
        alt="paymentIcon"
        style={{ width: 100 }}
        src={require('assets/images/우는스마일.png')}
      />
      <ResultNoneMessage>작성할 수 있는 구매후기가 없어요.</ResultNoneMessage>
    </ResultNoneWrap>
  );
};

export const WrittenReviewNone = () => {
  return (
    <ResultNoneWrap>
      <img alt="paymentIcon" src={require('assets/images/후기쓰기.png')} />
      <ResultNoneMessage>
        작성한 후기가 없습니다. <br /> 고객님의 소중한 첫 번째 후기를
        남겨주세요.
      </ResultNoneMessage>
    </ResultNoneWrap>
  );
};

import { authActions } from 'app/pages/App/authSlice';
import useAxios from 'axios-hooks';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import AppleLogin from 'react-apple-login';
import { Container } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import styled from 'styled-components';
import {
  API_HOST,
  CLIENT_ID,
  CLIENT_SECRET,
  KAKAO_JS_KEY,
  routes,
  APPLE_CLIENT_ID,
  APPLE_REDIRECT_URL,
  APPLE_SCOPE,
} from 'utils/const';
import { logger } from 'utils/helpers';
import { Link } from '../Common/Link';
import { ApiErrorAlert, ErrorAlert } from '../style/common/ErrorAlert';
import { LoginNone } from '../style/common/ResultIcon';
import { Spinner } from '../style/common/Spinner';

const LoginWrap = styled.article`
  width: 100%;
  height: 100%;
`;

const StartWrap = styled.div`
  padding: 0 56px 0 56px;
`;

const KakaoBtnWrap = styled.div`
  cursor: pointer;
  padding: 16px;
  background: #f9e000;
  color: #382018;
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
  text-align: center;

  img {
    width: 20px;
    margin-right: 8px;
  }
`;

const EmailWrap = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  margin-top: 20px;
`;

const EmailButton = styled(Link)`
  cursor: pointer;
  border-bottom: 1px solid;
  font-size: 13px;
  padding: 0 0 1px 0;
  \width: max-content;
  margin: 0 auto;
`;
const AppleButtonClick = styled.button`
  width: 335px;
  height: 48px;
  background: #3e3a39;
  border-radius: 4px;
  margin: 0 auto;
  text-align: center;
  display: block;
  font-weight: 900;
  font-size: 14px;
  color: #ffffff;
  align-self: center;
`;

// border-bottom: 1px solid #ddd;
export const NoneLogin = () => {
  return (
    <LoginWrap>
      <LoginNone />
      <StartWrap>
        <KakaoLoginButton />
      </StartWrap>
      {/* <StartWrap>
        <AppleButton />
      </StartWrap> */}
      <EmailWrap>
        <EmailButton to={routes.USER_LOGIN}>이메일로 로그인하기</EmailButton>
      </EmailWrap>
    </LoginWrap>
  );
};
export function AppleButton({ redirectURL = '', ...rest }) {
  const [authresponse] = useState('');
  const [token, setToken] = useState('');

  const appleResponse = response => {
    console.log(response);
    if (!response.error) {
      setToken(response.authorization.code);
    }
  };

  const [convertTokenResult, convertToken] = useAxios(
    {
      method: 'POST',
      url: `${API_HOST}/v1/auth/convert-token`,
    },
    { manual: true },
  );

  useEffect(() => {
    if (!!token) {
      const payload = {
        grant_type: 'convert_token',
        backend: 'apple',
        token,
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        for_web: true,
      };
      convertToken({ data: payload });
    }
  }, [token, convertToken]);

  const dispatch = useDispatch();
  const data = _.get(convertTokenResult, 'response.data.data');
  const status = _.get(convertTokenResult, 'response.status');
  const history = useHistory();
  React.useEffect(() => {
    if (status === 200) {
      logger.log('로그인 성공', redirectURL, data);

      const action = authActions.changeTokens({
        accessToken: _.get(data, 'access_token'),
        refreshToken: _.get(data, 'refresh_token'),
      });
      dispatch(action);
      history.push(!!redirectURL ? redirectURL : routes.MAIN);
    }
  }, [data, status, dispatch, redirectURL, history]);

  return (
    <div style={{ marginTop: 20 }}>
      <Container
        className="text-center mt-5"
        style={{ minHeight: '1px', background: 'transparent' }}
      >
        <div>
          {Object.keys(authresponse).length === 0 ? (
            <AppleLogin
              clientId={APPLE_CLIENT_ID}
              redirectURI={APPLE_REDIRECT_URL}
              usePopup={true}
              callback={appleResponse}
              scope={APPLE_SCOPE}
              responseMode="query"
              render={renderProps => (
                <AppleButtonClick onClick={renderProps.onClick} {...rest}>
                  <i
                    className="fa-brands fa-apple px-2 "
                    style={{
                      color: '#ffffff',
                      width: '16px',
                      marginRight: '8px',
                    }}
                  ></i>
                  Apple로 시작하기
                </AppleButtonClick>
              )}
            />
          ) : (
            <p style={{ fontFamily: 'none' }}>{JSON.stringify(authresponse)}</p>
          )}
        </div>
      </Container>
    </div>
  );
}

export default AppleButton;

export const loadSdk = () => {
  return new Promise(resolve => {
    const js: HTMLScriptElement = document.createElement('script');

    js.id = 'kakao-sdk';
    js.src = '//developers.kakao.com/sdk/js/kakao.min.js';
    js.onload = resolve;

    document.body.appendChild(js);
  });
};

export function KakaoLoginButton({ redirectURL = '', ...rest }) {
  useEffectOnce(() => {
    init();
    async function init() {
      await loadSdk();
      (window as any).Kakao.init(KAKAO_JS_KEY);
    }
  });

  const [token, setToken] = useState('');
  const [errMsg, setErrMsg] = useState('');
  function login() {
    (window as any).Kakao.Auth.login({
      success: function (response) {
        logger.log('카카오 로그인 성공', response);
        setToken(response.access_token);
      },
      fail: function (error) {
        logger.log('카카오 로그인 실패', error);
        setErrMsg('카카오 로그인 실패');
      },
    });
  }

  const [convertTokenResult, convertToken] = useAxios(
    {
      method: 'POST',
      url: `${API_HOST}/v1/auth/convert-token`,
    },
    { manual: true },
  );
  useEffect(() => {
    if (!!token) {
      const payload = {
        grant_type: 'convert_token',
        backend: 'kakao',
        token,
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
      };

      convertToken({ data: payload });
    }
  }, [token, convertToken]);

  const dispatch = useDispatch();
  const data = _.get(convertTokenResult, 'response.data.data');
  const status = _.get(convertTokenResult, 'response.status');
  const history = useHistory();
  React.useEffect(() => {
    if (status === 200) {
      logger.log('로그인 성공', redirectURL, data);
      console.log(data);
      const action = authActions.changeTokens({
        accessToken: _.get(data, 'access_token'),
        refreshToken: _.get(data, 'refresh_token'),
      });
      dispatch(action);
      history.push(!!redirectURL ? redirectURL : routes.MAIN);
    }
  }, [data, status, dispatch, redirectURL, history]);

  return (
    <>
      <KakaoBtnWrap onClick={login} {...rest}>
        <img alt="login" src={require('assets/images/ico_kakao_logo.png')} />
        카카오톡으로 시작하기
      </KakaoBtnWrap>
      <ErrorAlert
        errored={!!errMsg}
        msg={errMsg}
        onClose={() => setErrMsg('')}
      />
      <Spinner loading={convertTokenResult.loading} />
      <ApiErrorAlert apiResult={convertTokenResult} />
    </>
  );
}
